import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/header"

export default class About extends React.Component<any, any> {
  render() {
    return (
      <Layout>
        <Header />
        {/* {this.renderContent()} */}
      </Layout>
    )
  }

  renderContent() {
    return (
      <main className="content about">
        <section className="content__row content__row--bleed static-header__header">
          <h1 className="static-header__title content__row">We are Planty</h1>
          <div className="content__row static-header__content">
            <p>
              Well, not really. We’re <a href="http://bit.ly/2YB7AUL">Snipcart</a>—a bunch of geeks
              from Québec City. Planty is a theme we built for{" "}
              <a href="https://www.stackbit.com/">Stackbit</a>, a killer JAMstack site-builder. To
              enable e-commerce on Planty, you’ll need to{" "}
              <a href="http://bit.ly/2YzKmhP">sign up for Snipcart</a>. Testing is forever free, no
              credit card required.
            </p>
          </div>
        </section>

        <section className="content__row about__section">
          <picture>
            <source srcSet="\images\nan_talk.webp" type="image/webp" />
            <source srcSet="\images\nan_talk.jpg" type="image/jpeg" />
            <img src="\images\nan_talk.jpg" alt="We love plants" className="about__section-image" />
          </picture>
          <div className="about__section-content-container">
            <h2 className="about__section-title">We love plants</h2>
            <div className="about__section-content">
              <p>
                Now THAT is true, but we love web development even more. We talk about it on our
                blog, like, <a href="http://bit.ly/2YA6999">a lot</a>. We often craft live demos and
                open source these bad boys <a href="https://github.com/snipcart">on GitHub</a>.
                Matter of fact, this Planty theme{" "}
                <a href="https://github.com/snipcart/stackbit-theme-planty">is open source too</a>!
                If you spot any bugs, open an issue, and we’ll fire our junior dev. Just kidding
                Michael, you’re good.
              </p>
            </div>
          </div>
        </section>

        <section className="content__row about__section">
          <picture>
            <source srcSet="\images\team.webp" type="image/webp" />
            <source srcSet="\images\team.jpg" type="image/jpeg" />
            <img
              src="\images\team.jpg"
              alt="We think plants are the future"
              className="about__section-image"
            />
          </picture>
          <div className="about__section-content-container">
            <h2 className="about__section-title">We think plants are the future</h2>
            <div className="about__section-content">
              <p>
                Word. But good <strong>code</strong> <em>can</em> solve a lots of problems, too. New{" "}
                <em>and</em> old. That’s why we help beginners get started with web development
                through free content and tools, like this theme. We also believe{" "}
                <a href="https://jamstack.org/">the JAMstack</a> might be one solid pillar on which
                we build that future.
              </p>
            </div>
          </div>
        </section>

        <section className="content__row content__row--bleed">
          <div className="promo">
            <picture>
              <source srcSet="\images\promo.webp" type="image/webp" />
              <source srcSet="\images\promo.jpg" type="image/jpeg" />
              <img src="\images\promo.jpg" alt="A new home interior for summer" />
            </picture>

            <div className="promo__message-container">
              <h2 className="promo__title">A new home interior for summer</h2>
              <p className="promo__subtitle">from $149.99</p>
              <a className="link link--light promo__link" href="/store">
                Discover
                <svg
                  width="26"
                  height="14"
                  viewBox="0 0 26 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.6819 6.07886H0V7.79048H22.8139L18.2402 12.3182L19.434 13.5L26 7L19.434 0.5L18.2402 1.68182L22.6819 6.07886Z"
                    fill="#ffffff"
                  />
                </svg>
              </a>
            </div>
          </div>
        </section>
      </main>
    )
  }
}
